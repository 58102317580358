var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoading,"variant":"dark","opacity":0.25,"rounded":"sm"}},[_c('div',{staticClass:"budgeting-container"},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card-body',{staticClass:"mb-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"firstFocusInput",attrs:{"id":"name","size":"sm","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"Budgeting Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.period'),"label-for":"period"}},[_c('validation-provider',{attrs:{"name":"period","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"inputId":"period","options":_vm.LOV.year,"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.type'),"label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"inputId":"type","options":_vm.LOV.periodType,"reduce":function (field) { return field.value; },"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.periodType),callback:function ($$v) {_vm.$set(_vm.form, "periodType", $$v)},expression:"form.periodType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.branch'),"label-for":"branch"}},[_c('validation-provider',{attrs:{"name":"branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"inputId":"branch","options":_vm.LOV.lovBranch,"reduce":function (field) { return field.id; },"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.officeId),callback:function ($$v) {_vm.$set(_vm.form, "officeId", $$v)},expression:"form.officeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-button',{staticClass:"mt-2 pd-1",attrs:{"block":"","variant":"primary"},on:{"click":_vm.showingForm}},[_vm._v(" "+_vm._s(_vm.$t('globalActions.generate'))+" ")])],1)],1)],1),(_vm.loading)?_c('div',[_c('div',{staticClass:"my-2 text-center text-primary d-flex flex-column justify-content-center"},[_c('div',{staticClass:"mb-1 text"},[_c('b-spinner',{staticClass:"align-middle"})],1),_c('strong',[_vm._v(_vm._s(_vm.$t('globalActions.fetchingDataAPI')))])])]):_vm._e(),(_vm.showForm)?_c('b-table',{ref:"refListTable",staticClass:"position-relative",attrs:{"id":"refListTable","fields":_vm.tableColumns,"items":_vm.form.details,"sticky-header":"","responsive":"","primary-key":"id","show-empty":"","small":"","busy":_vm.tableBusy,"empty-text":_vm.$t('globalSingular.noMatching')},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"my-2 text-center text-primary d-flex flex-column justify-content-center"},[_c('div',{staticClass:"mb-1 text"},[_c('b-spinner',{staticClass:"align-middle"})],1),_c('strong',[_vm._v(_vm._s(_vm.$t('globalActions.fetchingDataAPI')))])])]},proxy:true},{key:"cell(coa)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:['d-block', 'text-nowrap', !item.isAccountParent ? 'pl-1' : 'font-weight-bolder' ]},[_vm._v(" "+_vm._s(item.label)+" ")])]}},{key:"cell(1)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[0].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 0); }}}):_vm._e()]}},{key:"cell(2)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[1].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 1); }}}):_vm._e()]}},{key:"cell(3)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[2].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 2); }}}):_vm._e()]}},{key:"cell(4)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[3].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 3); }}}):_vm._e()]}},{key:"cell(5)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[4].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 4); }}}):_vm._e()]}},{key:"cell(6)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[5].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 5); }}}):_vm._e()]}},{key:"cell(7)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[6].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 6); }}}):_vm._e()]}},{key:"cell(8)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[7].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 7); }}}):_vm._e()]}},{key:"cell(9)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[8].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 8); }}}):_vm._e()]}},{key:"cell(10)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[9].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 9); }}}):_vm._e()]}},{key:"cell(11)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[10].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 10); }}}):_vm._e()]}},{key:"cell(12)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isAccountParent)?_c('cleave-input',{attrs:{"value":_vm.form.details[index].amounts[11].amount,"disabled":_vm.actions.isPreview,"isAccountParent":item.isAccountParent},on:{"update-on-blur":function (value) { return _vm.onInput(value, index, 11); }}}):_vm._e()]}}],null,false,2746713955)}):_vm._e(),(_vm.showForm)?_c('b-card-body',[_c('b-row',{staticClass:"my-1"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.transactionsCashbank.singular.notes'),"label-for":"notes"}},[_c('b-form-textarea',{attrs:{"id":"notes","rows":"1","placeholder":!_vm.actions.isPreview ? _vm.$t('apps.transactionsCashbank.placeholder.description') : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":"outline-primary","to":{ name: 'apps-transactions-budgeting-list' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.backToList'))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:" mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.delete'))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t('globalActions.update') : _vm.$t('globalActions.create'))+" ")],1):_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.changeToEdit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.changeToEdit'))+" ")],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }