const moment = require('moment');

export function getYears(startYear = 2023) {
  const currentYear = moment().year(); // Get the current year
  const yearsArray = [];

  // Loop from the current year down to 2023
  for (let year = currentYear; year >= startYear; year--) {
    yearsArray.push(year);
  }

  return yearsArray;
}

export function getMonths(fullName = true) {
  const months = [];

  for (let i = 0; i < 12; i++) {
      if (!fullName) {
        months.push({
          label: moment().month(i).format('MMM'),
          value: i + 1
        });
      } else {
        months.push({
          label: moment().month(i).format('MMMM'),
          value: i + 1
        });
      }
  }

  return months;
}
