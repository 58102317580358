<template>
  <!-- <cleave
    :value="amount"
    :class="['w-auto text-right form-control form-control-sm']"
    :disabled="disabled"
    placeholder="1,000,000"
    @input="updateOnInput"
    @blur="updateOnBlur"
  /> -->
  <b-form-input
    :value="amount"
    type="number"
    :class="['w-auto text-right form-control form-control-sm']"
    :disabled="disabled"
    @input="updateOnInput"
    @blur="updateOnBlur"
  />
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
// import Cleave from 'vue-cleave-component';

export default {
  name: 'CleaveInput',

  components: {
    // Cleave,
    BFormInput
  },

  data() {
    return {
      amount: null,
      numeric: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      }
    }
  },

  props: {
    value: Number,
    disabled: Boolean,
    onBlur: Function,
  },

  emits: [
    'update-on-input',
    'update-on-blur',
  ],

  mounted() {
    const _value = `${typeof this.value}` === 'string' ? parseInt(`${this.value}`) : this.value
    this.amount = _value
  },

  methods: {
    updateOnInput(value) {
      this.$emit('update-on-input', value)
    },

    updateOnBlur(event) {
      this.$emit('update-on-blur', event.target.value)
    }
  }
};
</script>